import getStateValue from '../../../../../../helpers/getStateValue';

import { MAP_DASH_SORT } from './index';

const paramsToQueryString = (params) => Object
  .keys(params)
  .map((key) => `${key}=${encodeURIComponent(params[key])}`).join('&');

const ecommerceDashApiService = getStateValue('apiUrls.ecomDashApiUrl');

export default {
  categories: (instanceId, params = {}) => `${ecommerceDashApiService}product/all?instanceId=${instanceId}&${paramsToQueryString(
    params
  )}`,
  allProducts: (instanceId, params) => {
    const {
      sortBy,
      limit,
      offset,
      category: categoryId,
    } = params || {};
    const { key: sortKey, value: sortValue } = MAP_DASH_SORT[sortBy] || {};

    return `${ecommerceDashApiService}product/all?instanceId=${instanceId}&${paramsToQueryString(
      {
        ...(limit && { limit }),
        ...(offset && { offset }),
        ...(categoryId && { categoryId }),
        ...(sortKey && { [sortKey]: sortValue }),
      }
    )}`;
  },
  product: (instanceId, productId) => `${ecommerceDashApiService}product/details/?instanceId=${instanceId}&productId=${productId}`,
  currency: (instanceId) => `${ecommerceDashApiService}settings/currency?instanceId=${instanceId}`,
};
