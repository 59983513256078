import { IMG_BG_CLASS_NAME } from '../../../components/Section/constants';
import dom from '../../wrapper/DomWrapper';

import ImageBackground from './Backgrounds/Image';

class Section {
  constructor(elSection) {
    this.elSection = elSection;
    this.imgBg = null;
  }

  init = () => {
    this.initBackground();
  };

  initBackground = () => {
    const el = dom.getElement(`.section_bg .${IMG_BG_CLASS_NAME}`, this.elSection);

    if (!el) return;

    this.imgBg = new ImageBackground(el);
    this.imgBg.init();
  };
}

export default Section;
