import isEmpty from 'lodash/isEmpty';

import getFileExtension from '../urls/getFileExtension';

const NOT_RESIZABLE_TYPES = new Set(['svg', 'gif']);

/**
 * Util for check if image can be resizable
 * @param {string} src
 * @returns {boolean} isImageResizable
 */
export default function checkImageResizable(src) {
  const fileExtension = getFileExtension(src);

  return !isEmpty(fileExtension) && !NOT_RESIZABLE_TYPES.has(fileExtension);
}
