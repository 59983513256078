import {
  DESKTOP,
  LAPTOP,
  PHONE,
  QUAD,
  TABLET,
  ULTRA,
} from '../../../device';

const LAYOUT_TYPE = {
  grid: 'grid',
  masonry: 'masonry',
};

export const DEVICE_SIZES = {
  [PHONE]: [0, 767],
  [TABLET]: [768, 1024],
  [LAPTOP]: [1025, 1366],
  [DESKTOP]: [1367, 1920],
  [QUAD]: [1921, null],
};
export const FLUID_DEVICE_SIZES = {
  [QUAD]: [1921, 2559],
  [ULTRA]: [2560, null],
};

export default LAYOUT_TYPE;
