import { DEVICE_PRIORITY } from '../constants';

/**
 *
 * @param {string} deviceName
 * @param {string[]=} devices
 * @returns {string} previousDeviceName
 */
const getPreviousDeviceName = (deviceName, devices = DEVICE_PRIORITY) => {
  const deviceIndex = devices.indexOf(deviceName);

  return devices[deviceIndex - 1] || '';
};

export default getPreviousDeviceName;
