const CLASS_NAMES = {
  spinner: 'spinner',
  noGallery: '_no-gallery',
  restImagesWrap: 'ecom-product__img-wrap ecom-product__img-wrap',
  buttonSpinner: '_spinner',
  buttonActive: '_active',
  hidden: 'hidden',
  isAdding: 'isAdding',
};

const REST_IMAGES_MAX_SIZE = 4;

const LIGHT_BOX_OPTIONS = {
  // eslint-disable-next-line camelcase
  lightbox_theme: '_dark',
};

export {
  CLASS_NAMES,
  REST_IMAGES_MAX_SIZE,
  LIGHT_BOX_OPTIONS,
};
